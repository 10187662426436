


















































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import MembersGrid from '@/components/members/Grid.vue'

export default defineComponent({
  name: 'Vgouvernance',
  data() {
    return {
      // prettier-ignore
      /* eslint-disable */
      /* eslint-enable */
    }
  },
  components: {
    MembersGrid,
  },
  setup() {
    return {
      ...useGetters(['content', 'template']),
    }
  },
})
